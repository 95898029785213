@tailwind base;
@tailwind components;
@tailwind utilities;

/* NOTE: default installed components styling*/
@import '@szhsin/react-menu/dist/index.css';
@import '@szhsin/react-menu/dist/transitions/slide.css';
@import url('https:/*fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');


/* NOTE: custom styling for packages*/
@import './base.css';
@import './forms.css';
@import './buttonsAndLinks.css';

.undoButton,
.redoButton {
	border: none !important ;
	background-color: transparent !important ;
}
